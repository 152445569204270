import React from 'react';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { useInvitationContext } from '../../../contexts/InvitationContext';

const MarriageDateLandingPage = () => {
  const { marriageDate } = useInvitationContext(); // Anggap marriageDate diambil dari objek context
  const date = marriageDate ? new Date(marriageDate) : null; // Konversi marriageDate menjadi Date

  return (
    <>
      {date ? (
        <label>{format(date, 'MMM', { locale: id })}</label>
      ) : (
        <div className="h-2.5 animate-pulse bg-gray-400 rounded-full justify-self-center w-[50px]" />
      )}
      <label className='flex flex-col items-center border-x-2 border-[#ffefef33] border-opacity-20'>
        <span className='text-sm'>
          {date ? (
            <label>{format(date, 'EEE', { locale: id })}</label>
          ) : (
            <div className="h-2.5 animate-pulse bg-gray-400 rounded-full mb-3 w-[40px]" />
          )}
        </span>
        <span className='text-2xl'>
          {date ? (
            <label>{format(date, 'dd', { locale: id })}</label>
          ) : (
            <div className="h-2.5 animate-pulse bg-gray-400 rounded-full w-[50px]" />
          )}
        </span>
      </label>
      {date ? (
        <label>{format(date, 'yyyy', { locale: id })}</label>
      ) : (
        <div className="h-2.5 animate-pulse bg-gray-400 rounded-full justify-self-center w-[50px]" />
      )}
    </>
  );
};

export default MarriageDateLandingPage;