import React, { RefObject } from 'react';
import { domAnimation, LazyMotion, m, useInView } from 'framer-motion';
import { GiftIcon } from '../../../assets/images/svg/icon';
import DebitCard from '../../../components/DebitCard';
import { DeviderImg } from '../../../assets/images/svg/image';
import { useInvitationContext } from '../../../contexts/InvitationContext';
import Flower2SingleImg from '../../../assets/images/png/flower2-single.svg';
import Flower2Leaf1Img from '../../../assets/images/png/flower2-leaf1.svg';
import Flower2Leaf2Img from '../../../assets/images/png/flower2-leaf2.svg';
import WaterColor3 from '../../../assets/images/webp/watercolor_3.webp';
import WaterColor2 from '../../../assets/images/webp/watercolor_2.webp';
import RunningTextOpacityEffect from '../../../components/RunningTextOpacityEffect';

const renderDebitCard = (item: { idEvent: string; bankName: string; accountNumber: string; cardHolderName: string; }[], motion?: {scale: number}) => {
  const component: JSX.Element[] = [];
  

  item.forEach((value: { cardHolderName: string; accountNumber: string; }, index) => {
    component.unshift(<DebitCard key={index} name={value.cardHolderName} noAccount={value.accountNumber} motion={motion} />);
  });

  return component;
};

const LoveGiftSection = ({reference} : {reference: RefObject<HTMLDivElement>}) => {
  const {data: {bankAccount}} = useInvitationContext();
  const isInView = useInView(reference);


  return (
  <section id='loveGiftSection' ref={reference} className='flex flex-col relative bg-[#260514] pt-40 pb-36 px-5 text-[#F1EFEA] z-30'>
      <LazyMotion features={domAnimation}>
        <div className='absolute -left-60 top-0'>
          <img
            src={WaterColor3}
            alt='img_watercolor_3'
            loading='lazy'
            className='object-cover'
          />
        </div>
        <div className='absolute -right-44 top-40 opacity-20'>
          <img
            src={WaterColor2}
            alt='img_watercolor_3'
            loading='lazy'
            className='object-cover'
          />
        </div>
        <div className='w-full absolute top-[-7px] left-[0.5px] z-10'>
          <DeviderImg />
        </div>
        <div className='relative top-[-250px] w-full z-10'>
          <div className='w-[inherit] absolute left-[-100px]'>
            <m.div
              initial={{ rotate: 5 }}
              animate={isInView && { rotate: [5, 0, 5, 0, 5] }}
              transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
              className='absolute'
            >
              <img
                src={Flower2Leaf1Img}
                alt='flower_loveGift_right_leaf2'
                width={250}
                loading='lazy'
              />
            </m.div>
            <m.div
              initial={{ rotate: -10 }}
              animate={isInView && { rotate: [-10, -5, -10, -5, -10] }}
              transition={{ duration: 10, delay: 1, repeat: Infinity, ease: 'easeInOut' }}
              className='absolute'
            >
              <img
                src={Flower2Leaf2Img}
                alt='flower_loveGift_right_leaf1'
                width={250}
                loading='lazy'
              />
            </m.div>
            <img
              src={Flower2SingleImg}
              alt='flower_loveGift_right'
              width={250}
              loading='lazy'
              className='absolute'
            />
          </div>
          <div className='w-[inherit] absolute right-[-100px] -scale-x-[1]'>
            <m.div
              initial={{ rotate: 5 }}
              animate={isInView && { rotate: [5, 0, 5, 0, 5] }}
              transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
              className='absolute'
            >
              <img
                src={Flower2Leaf1Img}
                alt='flower_loveGift_left_leaf1'
                width={250}
                loading='lazy'
              />
            </m.div>
            <m.div
              initial={{ rotate: -10 }}
              animate={isInView && { rotate: [-10, -5, -10, -5, -10] }}
              transition={{ duration: 10, delay: 1, repeat: Infinity, ease: 'easeInOut' }}
              className='absolute'
            >
              <img
                src={Flower2Leaf2Img}
                alt='flower_loveGift_left_leaf2'
                width={250}
                loading='lazy'
              />
            </m.div>
            <img
              src={Flower2SingleImg}
              alt='flower_loveGift_left'
              width={250}
              loading='lazy'
              className='absolute'
            />
          </div>
        </div>
        <div className='self-center text-center space-y-1 z-10 pb-9'>
          <div className='w-[40px] h-[40px] bg-[#781444] rounded-full mx-auto flex items-center justify-center text-[#F1EFEA]'>
            <GiftIcon />
          </div>
          <h3 className='font-playFair pt-2 text-3xl'>Hadiah Pernikahan</h3>
          <RunningTextOpacityEffect text={'Tanpa mengurangi rasa hormat kami, bagi anda yang ingin memberikan tanda kasih, kami sertakan informasi rekening berikut.'} textAlign='center' />
        </div>
        <div className='flex flex-col gap-5'>
          {renderDebitCard(bankAccount)}
        </div>
      </LazyMotion>
  </section>
  );
};

export default React.memo(LoveGiftSection);
