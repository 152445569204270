import React from 'react';
import { domAnimation, LazyMotion, m, useTransform } from 'framer-motion';
import { TornPaperImg } from '../../../assets/images/svg/image';
import { sectionProps } from '../types';
import { Flower1 } from '../../../assets/images/svg/image';
import { Leaf } from '../../../components/Leaf';
import { useInvitationContext } from '../../../contexts/InvitationContext';
import MarriageDateLandingPage from '../../LandingPage/components/MarriageDateLandingPage';
import { apiUrl } from '../../../utils/GLOBAL_VARIABLE';

const HomeSection = ({ reference, scroll: { scrollY } }: sectionProps) => {
  const {data: {invitation}} = useInvitationContext();
  const nameCandidate = invitation?.nameCandidate?.split(' ');
  const namePartner = invitation?.namePartner?.split(' ');

  const opacity = useTransform(
    scrollY,
    [0, 420, 500],
    [1, 0.3, 0]
  );

  const scale = useTransform(
    scrollY,
    [0, 420],
    [1.4, 1.2]
  );
  

  const quotes = ` “Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri,
  agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang.”`;
  return (
    <section id='homeSection' ref={reference} className='bg-[#260514] flex h-fit flex-col relative text-[#F1EFEA] overflow-hidden '>
      <LazyMotion features={domAnimation}>
        <div className='w-[80%] h-[600px] absolute mx-auto left-0 right-0 top-10 bg-gradient-to-b from-[#5d0030] to-[#410222] rounded-[130px] before:rounded-[110px] before:border-[1px] before:border-[#A47E3B] before:w-[88%] before:h-[calc(680px-40px)] before:left-0 before:right-0 before:top-5 before:my-0 before:mx-auto before:absolute' />
        <Leaf />
        <m.div
          initial={{ rotate: -112 }}
          animate={{ rotate: [-112, -120, -112, -120, -112] }}
          transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
          className='w-1/2 right-[-30px] top-[20%] absolute'
        >
          <Flower1 />
        </m.div>
        <m.div
          initial={{ rotate: 162 }}
          animate={{ rotate: [162, 170, 162, 170, 162] }}
          transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
          className='w-1/2 left-[-10px] top-[20%] rotate-[170deg] absolute'
        >
          <Flower1 />
        </m.div>
        <m.div
          style={{ scale }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, ease: [0.45, 0, 0.55, 1], delay: 0.9 }}
          className='absolute w-[90%] top-[140px] left-4 z-20'
        >
          <img
            src={`${apiUrl}/${invitation?.groomBridePhoto}`}
            alt='bride_and_groom'
            className='object-contain w-full'
            loading='lazy'
          />
        </m.div>
        <div className='z-30'>
          <div className='w-full h-[800px] absolute bg-gradient-to-b from-transparent from-10% to-50% to-[#260615] ' />
          <m.div
            initial={{ y: '0%' }}
            animate={{ y: '5%' }}
            transition={{ duration: 0.6, ease: [0.45, 0, 0.55, 1] }}
            className='flex flex-col text-center space-y-5 pt-[200px] px-5 pb-36'>
            <label className='font-poppins uppercase tracking-[15px] flex items-center flex-col pl-3'>
              {
                invitation?.idInvitation
                ? <span className='text-lg'> Pernikahan </span>
                : <div className="h-2.5 animate-pulse bg-gray-400 rounded-full w-[220px]" />
              }
            </label>
            <label className='font-playFair relative flex justify-center uppercase text-5xl font-bold space-x-4'>
              {
                nameCandidate && nameCandidate.length > 1
                  ? <span className='relative text-[#efbe67] z-10'>{nameCandidate[0]}</span>
                  : <div className="h-3.5 animate-pulse bg-gray-400 rounded-full w-[120px]" />
              }
              <span className='absolute left-[28%] top-[-55px] text-[150px] opacity-20'>
                &
              </span>
              {
                namePartner && namePartner.length > 1
                  ? <span className='relative text-[#efbe67] z-10'>{namePartner[0]}</span>
                  : <div className="h-3.5 animate-pulse bg-gray-400 rounded-full w-[120px]" />
              }
            </label>
            <div className='grid grid-cols-3 uppercase justify-center items-center text-3xl mx-auto min-w-[300px]'>
            <MarriageDateLandingPage />
            </div>
            <m.div
              style={{ opacity }}
              className='space-y-4 flex flex-col self-center w-full text-[#F1EFEA]' >
              {
                invitation?.idInvitation
                ? (
                  <>
                    <m.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 0.6 }}
                      transition={{ delay: 0.6, duration: 0.3, ease: [0.45, 0, 0.55, 1] }}
                      className='text-xs leading-5 font-libreBaskerville italic'>
                      {quotes}
                    </m.p><m.label
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.7, duration: 0.3, ease: [0.45, 0, 0.55, 1] }}
                      className='uppercase'
                    >
                        ( QS. Ar-Rum :21 )
                      </m.label>
                  </>
                )
                : (
                  <div className='animate-pulse flex flex-col items-center space-y-7'>
                    <div className='flex flex-col items-center space-y-2'>
                      <div className="h-2.5 bg-gray-300 rounded-full w-[250px]" />
                      <div className="h-2.5 bg-gray-300 rounded-full w-[300px]" />
                      <div className="h-2.5 bg-gray-300 rounded-full w-[280px]" />
                      <div className="h-2.5 bg-gray-300 rounded-full w-[230px]" />
                    </div>
                    <div className="h-2.5 bg-gray-400 rounded-full w-[100px]" />
                  </div>
                )
              }
            </m.div>
          </m.div>
        </div>
        <div className='text-[#F1EFEA] w-full z-40'>
          <TornPaperImg />
        </div>
      </LazyMotion>
    </section >
  );
};

export default React.memo(HomeSection);