import { throttle } from 'lodash';
import { useEffect, useState } from 'react';

const useActiveSection = (sectionRefs: React.RefObject<HTMLDivElement>[]) => {
  const [activeSection, setActiveSection] = useState('homeSection');
  const offSet = 50;

  useEffect(() => {
    const handleScroll = throttle(() => {
      const scrollPosition = window.scrollY + offSet;

      const sectionInView = sectionRefs.find(({ current }) => 
        current && scrollPosition >= current.offsetTop && scrollPosition < current.offsetTop + current.offsetHeight
      );

      setActiveSection(sectionInView?.current?.id || '');
    }, 100); // Throttle scroll event to run at most every 100ms

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);

  }, [sectionRefs, offSet]);


  return activeSection;
};

export default useActiveSection;
