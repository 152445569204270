import { useEffect, useState } from 'react';

const useMetadata = ({ title, loadingTime }: { title?: string, loadingTime?: number }) => {
  const [isLoading, setIsLoading] = useState(true);
  const time = loadingTime ? loadingTime : 1;

  useEffect(() => {
    // Fungsi ini akan dijalankan setiap kali komponen dirender ulang

    // Tentukan interval yang diinginkan (dalam milidetik)
    const interval = setInterval(() => {
      setIsLoading(false);
    }, time * 1000); // Contoh interval 2 detik (2000 milidetik)

    document.title = title || 'weddingSibayu.id';

    // Fungsi ini akan dijalankan saat komponen unmount atau saat nilai dari dependencies berubah
    return () => {
      // Hentikan interval ketika komponen di-unmount atau saat nilai dari dependencies berubah
      clearInterval(interval);
    };
  }, [time, title]);

  return {
    isLoading
  };
};

export default useMetadata;