import { faGithubSquare, faInstagramSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faHeart } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import LoveSign from '../../assets/images/webp/love-sign.webp';
import Love from '../../assets/images/webp/love.webp';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useInvitationContext } from '../../contexts/InvitationContext';
import { DeviderImg } from '../../assets/images/svg/image';


const Footer = () => {
  const { data: {invitation} } = useInvitationContext();
  const mail = 'mailto:bayuridhoalkhifli@gmail.com';
  const instagram = 'https://www.instagram.com/ridhoalkhifli/';
  const github = 'https://github.com/BayuRidhoAlkhifli';
  const linkedin = 'https://www.linkedin.com/in/bayu-ridho-alkifli-33b034143';
  

  return (
    <footer className="text-center footer pb-44 space-y-4">
      <LazyMotion features={domAnimation}>
      <div className='py-12 px-5'>
        <p className='opacity-60'>
          Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i {invitation?.nameGuest} berkenan hadir untuk memberikan doa dan restunya.
        </p>
        <h4 className='font-playFair pt-2 text-xl'>Wassalamualaikum Wr. Wb</h4>
        <div className='flex justify-center mt-14'>
          <div className='relative'>
            <m.div
              initial={{ scale: 1 }}
              whileInView={{ scale: [1, 1.2, 1] }}
              transition={{ 
                duration: 0.6, 
                repeat: Infinity,
                ease: 'easeInOut',
                times: [0, 0.5, 1]
              }}
            >
              <img
                src={Love}
                alt='love_hand'
                width={30}
                loading='lazy'
                className='absolute left-3 -top-6'
              />
            </m.div>
            <img
              src={LoveSign}
              alt='love_hand'
              width={50}
              loading='lazy'
            />
          </div>
        </div>
      </div>
      <DeviderImg />
      <label className="title-vb title-big-prj-item pt-12">Get In Touch</label>
      <div>
        <Link to={mail} className="btn ml-1 btn-transparent" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faEnvelope} />
        </Link>
        <Link to={instagram} className="btn ml-1 btn-transparent" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagramSquare} />
        </Link>
        <Link to={github} className="btn ml-1 btn-transparent" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithubSquare} />
        </Link>
        <Link to={linkedin} className="btn ml-1 btn-transparent" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </Link>
      </div>
      <div className="txt-color-06">
        <span className="d-block">&copy; 2024 siBayu </span>
        <span className="d-block">Made with <FontAwesomeIcon icon={faHeart} /> in Jakarta, Indonesia</span>
      </div>
      </LazyMotion>
    </footer>
  );
};

export default Footer;