import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import InvitationPage from './pages/InvitationPage';
import useUnselectable from './hooks/useUnselectable';
import InvitationProvider from './contexts/InvitationContext';
import NotFoundPage from './pages/ErrorPage/NotFoundPage';



const App = () => {
  const router = createBrowserRouter([
    {
      path: '/RizkiaBayu',
      element: <LandingPage />,
    },
    {
      path: '/RizkiaBayu/details',
      element: <InvitationPage />,
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);

  useUnselectable();

  return (
    <React.StrictMode>
      <InvitationProvider>
        <RouterProvider router={router} />
      </InvitationProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
