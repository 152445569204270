import React from 'react';
import NotFound from '../../assets/images/webp/illustration_not_found.webp';


const NotFoundPage = () => {
  return <main className='h-screen min-h-[750px] bg-[#E6E6E6] text-neutral-800 relative overflow-hidden'>
    <section className='flex px-5 h-full justify-center items-center flex-col'>
    <div>
      <img
        src={NotFound}
        alt='not_found_illustration'
        width={350}
        loading='lazy'
      />
    </div>
    <p className='capitalize text-center opacity-50'>Oops! The page you&apos;re looking for doesn&apos;t exist.</p>
    </section>
  </main>;
};

export default NotFoundPage;
