import { differenceInSeconds } from 'date-fns';
import { useEffect, useState } from 'react';

export const useCountdownTimer = (date: Date | null) => {
  const [timeRemaining, setTimeRemaining] = useState<Record<string, number>>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }); // Inisialisasi state kosong

  useEffect(() => {
    if (!date) {
      return; // Jika 'date' adalah null, tidak perlu melakukan apa-apa
    }

    const calculateTimeRemaining = () => {
      const secondsRemaining = differenceInSeconds(date, new Date());
      const days = Math.floor(secondsRemaining / (60 * 60 * 24));
      const hours = Math.floor((secondsRemaining % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((secondsRemaining % (60 * 60)) / 60);
      const seconds = secondsRemaining % 60;
      return { days, hours, minutes, seconds };
    };

    const interval = setInterval(() => {
      const remainingTime = calculateTimeRemaining();
      setTimeRemaining(remainingTime);
    }, 1000); // Perbarui setiap detik

    return () => clearInterval(interval); // Membersihkan interval saat komponen unmount
  }, [date]); // useEffect hanya dijalankan sekali saat komponen dipasang

  return timeRemaining;
};