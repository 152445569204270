import React, { useRef } from 'react';
import { m, useScroll, useTransform, easeIn, LazyMotion, domAnimation } from 'framer-motion';
import { DoubleHeartIcon } from '../../../assets/images/svg/icon';
import { sectionProps } from '../types';
import { useInvitationContext } from '../../../contexts/InvitationContext';
import { apiUrl } from '../../../utils/GLOBAL_VARIABLE';
import bgPattern from '../../../assets/images/webp/pattern.webp';
import RunningTextOpacityEffect from '../../../components/RunningTextOpacityEffect';

const BrideSection = ({ reference ,scroll: { scrollY } }: sectionProps) => {
  const ref = useRef(null);
  const {data: {invitation}} = useInvitationContext();
  

  const { scrollYProgress } = useScroll({
    target: ref,
    layoutEffect: false, 
  });

  const opacityFlowerGroom = useTransform(
    scrollY,
    [0, 500, 760],
    [0, 0, 1]
  );

  const opacityFlowerBride = useTransform(
    scrollY,
    [0, 800, 960],
    [0, 0, 1]
  );

  const brideGroomPhotoY = useTransform(scrollYProgress, [1, 0], ['-80%', '0%'], { clamp: false, ease: easeIn });
  const brideGroomScale = useTransform(scrollYProgress, [0, 1], ['1', '1.3'], { clamp: false, ease: easeIn });
  const bridePhotoY = useTransform(scrollYProgress, [0, 1], ['0%', '80%'], { clamp: false, ease: easeIn });
  const brideScale = useTransform(scrollYProgress, [1, 0], ['1.3', '1'], { clamp: false, ease: easeIn });


  const brideGroomPhotoRotate = useTransform(
    scrollY,
    [0, 130],
    [-8, -9],
    { clamp: false }
  );

  const bridePhotoRotate = useTransform(
    scrollY,
    [0, 200],
    [12, 13],
    { clamp: false }
  );

  return (
    <section
      id='brideSection'
      ref={reference}
      className='flex flex-col relative pt-24 pb-48 bg-cover px-5 text-[#260514] z-40'
      style={{backgroundImage: `url(${bgPattern})`}}>
        <LazyMotion features={domAnimation}>
          <div className='absolute bg-gradient-to-t from-[#F1EFEA] via-[#ece2d5f2] to-[#F1EFEA] w-full h-full left-0 top-0' />
          <div ref={ref} className='self-center text-center space-y-1 z-10'>
            <div className='w-[40px] h-[40px] bg-[#781444] rounded-full mx-auto flex items-center justify-center text-white'>
              <DoubleHeartIcon />
            </div>
            <h3 className='font-playFair pt-2 text-3xl'>Pengantin</h3>
            <h4 className='font-playFair pt-2 pb-1 text-xl'>Assalamu&apos;alaikum Wr. Wb</h4>
            <RunningTextOpacityEffect text={'Dengan memohon rahmat dan ridho Allah S.W.T, Insya Allah kami bermaksud menyelenggarakan syukuran pernikahan'} textAlign='center' />
          </div>
          <div className='relative pt-14 z-30'>
            <m.div
              style={{ opacity: opacityFlowerGroom }}
              className='top-[0px] right-[179px] absolute z-10 w-[137px]'
              transition={{ duration: 0.6, ease: [0.45, 0, 0.55, 1], delay: 2 }}>
            </m.div>
            <m.div style={{ y: brideGroomPhotoY, rotate: brideGroomPhotoRotate, scale: brideGroomScale, x: -30 }} className='bg-white p-[16px] rounded-lg h-[280px] w-[220px] mx-auto shadow-xl relative z-10'>
              <m.div
                initial={{ filter: 'blur(4px)', WebkitFilter: 'blur(4px)', }}
                whileInView={{ filter: 'blur(0px)', WebkitFilter: 'blur(0px)' }}
                className='w-full h-[80%] relative'
                transition={{ duration: 0.6, ease: [0.45, 0, 0.55, 1] }}>
                <img
                  src={`${apiUrl}/${invitation?.candidatePhoto}`}
                  alt='bride_and_groom'
                  className='object-contain'
                  loading='lazy'
                />
              </m.div>
            </m.div>
            <div className='flex flex-col w-[250px] absolute right-0 bottom-1 text-right z-10'>
              <label className='bg-[#26051492] text-[#F1EFEA] backdrop-blur-sm w-fit ml-auto px-2'>Pengantin Wanita</label>
              <label className='text-3xl font-playFair bg-[#26051492] text-[#F1EFEA] w-fit ml-auto backdrop-blur-sm px-2'>{invitation?.nameCandidate}</label>
              <label className='font-libreBaskerville text-sm mt-1 opacity-60'>Putri dari Bpk. {invitation?.fatherNameCandidate} & Ibu {invitation?.motherNameCandidate}</label>
            </div>
          </div>
          <div className='relative z-20'>
            <m.div
              style={{ opacity: opacityFlowerBride }}
              className='bottom-[-94px] right-[74px] absolute w-[145px]'
              transition={{ duration: 0.6, ease: [0.45, 0, 0.55, 1], delay: 2 }}>
            </m.div>
            <m.div style={{ y: bridePhotoY, rotate: bridePhotoRotate, scale: brideScale, x: 30 }} className='bg-white p-[16px] rounded-lg h-[280px] w-[220px] translate-x-10 mx-auto shadow-xl z-10'>
              <m.div
                initial={{ filter: 'blur(4px)', WebkitFilter: 'blur(4px)', }}
                whileInView={{ filter: 'blur(0px)', WebkitFilter: 'blur(0px)' }}
                className='w-full h-[80%] relative'
                transition={{ duration: 0.6, ease: [0.45, 0, 0.55, 1] }}>
                <img
                  src={`${apiUrl}/${invitation?.partnerPhoto}`}
                  alt='bride_and_groom'
                  className='object-contain'
                  loading='lazy'
                />
              </m.div>
            </m.div>
            <div className='flex flex-col w-[250px] absolute bottom-1'>
              <label className='bg-[#26051492] text-[#F1EFEA] backdrop-blur-sm w-fit mr-auto px-2'>Pengantin Pria</label>
              <label className='text-3xl font-playFair bg-[#26051492] text-[#F1EFEA] w-fit backdrop-blur-sm px-2'>{invitation?.namePartner}</label>
              <label className='font-libreBaskerville text-sm mt-1 opacity-60'>Putra dari Bpk. {invitation?.fatherNamePartner} & Ibu {invitation?.motherNamePartner}</label>
            </div>
          </div>
        </LazyMotion>
    </section>
  );
};

export default React.memo(BrideSection);