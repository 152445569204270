import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import CountdownTimer from './CountdownTimer';
import { Link } from 'react-router-dom';
import { MailOpenedIcon } from '../../assets/images/svg/icon';
import { FlowerVerticalLeafL1, FlowerVerticalLeafL2, FlowerVerticalLeafR1, FlowerVerticalLeafR2, FlowerVerticalSingle } from '../../assets/images/svg/image';
// import Flower1Img from '../../assets/images/png/flower1.png';
// import Flower2Img from '../../assets/images/png/flower2.png';
import Loader from '../../components/Loader';
import useMetadata from '../../hooks/useMetadata';
import TextGuest from './components/TextGuest';
import MarriageDateLandingPage from './components/MarriageDateLandingPage';
import { useInvitationContext } from '../../contexts/InvitationContext';
import useQuery from '../../hooks/useQuery';
import useGetInvitationData from '../../utils/hooks/useGetInvitationData';
import Flower2SingleImg from '../../assets/images/png/flower2-single.svg';
import Flower2Leaf1Img from '../../assets/images/png/flower2-leaf1.svg';
import Flower2Leaf2Img from '../../assets/images/png/flower2-leaf2.svg';
import Flower2Leaf3Img from '../../assets/images/png/flower2-leaf3.svg';
import Flower2Leaf4Img from '../../assets/images/png/flower2-leaf4.svg';
import Flower5Single1Img from '../../assets/images/svg/flower5_single_1.svg';
import Flower5Single2Img from '../../assets/images/svg/flower5_single_2.svg';
import Flower5Left1Img from '../../assets/images/svg/flower5_leaf_L1_single.svg';
import Flower5Left2Img from '../../assets/images/svg/flower5_leaf_L2_single.svg';
import Flower5Right1Img from '../../assets/images/svg/flower5_leaf_R1_single.svg';
import Flower5Right2Img from '../../assets/images/svg/flower5_leaf_R2_single.svg';
import NotFoundPage from '../ErrorPage/NotFoundPage';



const LandingPage = () => {
  const { isLoading } = useMetadata({ title: 'Wedding siBayu.id - Rizkia & Bayu Special Day', loadingTime: 2 });

  const {data: {invitation}} = useGetInvitationData();
  const invitationId = useQuery().get('invitation');  
  const {marriageDate} = useInvitationContext();  

  const nameCandidate = invitation?.nameCandidate?.split(' ');
  const namePartner = invitation?.namePartner?.split(' ');

  const renderLandingPage = () => {

    if(!invitation) {
      return <NotFoundPage />;
    }
    
    return (
      <section className='flex items-center h-full text-[#F1EFEA] bg-[#260514]'>
        <div className='flex max-w-[450px] mx-auto justify-center'>
          <div className='flex flex-col uppercase text-center'>
            <div className='relative bg-gradient-to-b from-[#5d0030] to-[#410222] before:border-[1px] before:border-[#A47E3B] backdrop-blur-sm rounded-[130px] before:rounded-[110px] w-[360px] before:w-[calc(360px-40px)] h-[680px] before:h-[calc(680px-40px)] before:left-0 before:right-0 before:my-0 before:mx-auto flex before:absolute flex-col self-center space-y-5 justify-center z-10'>
              <motion.div
                initial={{ scale: 0, zIndex: 20, rotate: 132 }}
                animate={{ scale: 0.5 }}
                transition={{ duration: 0.5, ease: [0.45, 0, 0.55, 1] }}
                className='absolute w-full top-24 -right-48'
              >
                <motion.div
                  initial={{ rotate: -5 }}
                  animate={{ rotate: [-5, 0, -5, 0, -5] }}
                  transition={{ duration: 10, delay: 1, repeat: Infinity, ease: 'easeInOut' }}
                  className='absolute top-[24px] left-[19px]'
                >
                  <img
                    src={Flower5Left1Img}
                    alt='flower_landingPage_top_leaf1'
                    width={320}
                    loading='lazy'
                  />
                </motion.div>
                <motion.div
                  initial={{ rotate: -5 }}
                  animate={{ rotate: [-5, 0, -5, 0, -5] }}
                  transition={{ duration: 10, delay: 1, repeat: Infinity, ease: 'easeInOut' }}
                  className='absolute top-[13px] left-[15px]'
                >
                  <img
                    src={Flower5Left2Img}
                    alt='flower_landingPage_top_leaf2'
                    width={320}
                    loading='lazy'
                  />
                </motion.div>
                <img
                  src={Flower5Single2Img}
                  alt='flower_top_right'
                  loading='lazy'
                  className='absolute'
                />
                <motion.div
                  initial={{ rotate: -5 }}
                  animate={{ rotate: [-5, 0, -5, 0, -5] }}
                  transition={{ duration: 10, delay: 1, repeat: Infinity, ease: 'easeInOut' }}
                  className='absolute top-[25px] left-[22px]'
                >
                  <img
                    src={Flower5Right1Img}
                    alt='flower_landingPage_top_right2'
                    width={320}
                    loading='lazy'
                  />
                </motion.div>
                <motion.div
                  initial={{ rotate: -5 }}
                  animate={{ rotate: [-5, 0, -5, 0, -5] }}
                  transition={{ duration: 10, delay: 1, repeat: Infinity, ease: 'easeInOut' }}
                  className='absolute top-[25px] left-[22px]'
                >
                  <img
                    src={Flower5Right2Img}
                    alt='flower_landingPage_middle_leaf1'
                    width={320}
                    loading='lazy'
                  />
                </motion.div>
                <img
                  src={Flower5Single1Img}
                  alt='flower_landingPage_top_right1'
                  loading='lazy'
                  className='absolute'
                />
              </motion.div>
              <div className='relative'>
                <div className='mx-auto absolute top-0 left-0 right-0 w-[130px] h-auto'>
                  <motion.div
                    initial={{ rotate: 0 }}
                    animate={{ rotate: [3, 0, 3, 0, 3] }}
                    transition={{ duration: 3, repeat: Infinity, ease: 'easeInOut' }}
                    
                  >
                    <FlowerVerticalLeafL2 className='absolute top-1' />
                  </motion.div>
                  <motion.div
                    initial={{ rotate: 0 }}
                    animate={{ rotate: [3, 0, 3, 0, 3] }}
                    transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
                    
                  >
                    <FlowerVerticalLeafL1 className='absolute top-0' />
                  </motion.div>
                  <motion.div
                    initial={{ rotate: 0 }}
                    animate={{ rotate: [0, 3, 0, 3, 0] }}
                    transition={{ duration: 3, repeat: Infinity, ease: 'easeInOut' }}
                    
                  >
                    <FlowerVerticalLeafR2 className='absolute top-1' />
                  </motion.div>
                  <motion.div
                    initial={{ rotate: 0 }}
                    animate={{ rotate: [0, 3, 0, 3, 0] }}
                    transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
                    
                  >
                    <FlowerVerticalLeafR1 className='absolute top-0' />
                  </motion.div>
                </div>
                <FlowerVerticalSingle className='mx-auto relative w-[130px] h-auto' />
              </div>
              <label className='font-poppins tracking-[15px] flex flex-col pl-3'>
                <span className='text-sm'>
                  Undangan
                </span>
                <span className='text-lg'>
                  Pernikahan
                </span>
              </label>
              <label className='relative flex self-center font-playFair text-5xl font-bold space-x-4'>
                {
                  nameCandidate && nameCandidate.length > 1
                    ? <span className='relative text-[#efbe67] z-10'>{nameCandidate[0]}</span>
                    : <div className="h-3.5 animate-pulse bg-gray-400 rounded-full w-[120px]" />
                }
                <span className='absolute left-[25%] top-[-55px] text-[150px] opacity-20'>
                  &
                </span>
                {
                  namePartner && namePartner.length > 1
                    ? <span className='relative text-[#efbe67] z-10'>{namePartner[0]}</span>
                    : <div className="h-3.5 animate-pulse bg-gray-400 rounded-full w-[120px]" />
                }
              </label>
              <div className='grid grid-cols-3 justify-center items-center text-3xl mx-auto min-w-[300px]'>
                <MarriageDateLandingPage />
              </div>
              <CountdownTimer date={marriageDate} />
              <div className='flex flex-col text-center relative left-0 right-0 top-3 px-5 pb-0 mx-auto z-20'>
                <TextGuest />
                <Link
                  to={`/RizkiaBayu/details?invitation=${invitationId}`}
                  className='w-fit flex flex-row space-x-2 mt-5 transition ease-in-out duration-300 bg-gradient-to-r from-[#e8b855] to-[#954d3c] text-[#260514] py-2 px-4 rounded-lg self-center shadow-xl hover:shadow-md'>
                  <MailOpenedIcon /> <span>Buka Undangan</span>
                </Link>
              </div>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, ease: [0.45, 0, 0.55, 1] }}
                className='absolute -left-[20px] bottom-[160px] w-[inherit]'
              >
                <motion.div
                  initial={{ rotate: -15 }}
                  animate={{ rotate: [-15, -20, -15, -20, -15] }}
                  transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
                  className='absolute'
                >
                  <img
                    src={Flower2Leaf1Img}
                    alt='flower_landingPage_bottom_leaf1'
                    width={250}
                    loading='lazy'
                  />
                </motion.div>
                <motion.div
                  initial={{ rotate: -15 }}
                  animate={{ rotate: [-15, -10, -15, -10, -15] }}
                  transition={{ duration: 10, delay: 1, repeat: Infinity, ease: 'easeInOut' }}
                  className='absolute -top-3'
                >
                  <img
                    src={Flower2Leaf2Img}
                    alt='flower_landingPage_bottom_leaf2'
                    width={250}
                    loading='lazy'
                  />
                </motion.div>
                <motion.div
                  initial={{ rotate: -10 }}
                  animate={{ rotate: [-10, -5, -10, -5, -10] }}
                  transition={{ duration: 10, delay: 1, repeat: Infinity, ease: 'easeInOut' }}
                  className='absolute top-[13px]'
                >
                  <img
                    src={Flower2Leaf3Img}
                    alt='flower_landingPage_bottom_leaf3'
                    width={250}
                    loading='lazy'
                  />
                </motion.div>
                <motion.div
                  initial={{ rotate: -5 }}
                  animate={{ rotate: [-5, 0, -5, 0, -5] }}
                  transition={{ duration: 10, delay: 1, repeat: Infinity, ease: 'easeInOut' }}
                  className='absolute top-[25px]'
                >
                  <img
                    src={Flower2Leaf4Img}
                    alt='flower_landingPage_bottom_leaf4'
                    width={250}
                    loading='lazy'
                  />
                </motion.div>
                <img
                  src={Flower2SingleImg}
                  alt='flower_landingPage_bottom'
                  width={270}
                  loading='lazy'
                  className='absolute rotate-[-10deg]'
                />
              </motion.div>
            </div>
          </div>

        </div>
      </section>
    );
  };
  

  return (
    <AnimatePresence>
      <main className='h-screen min-h-[750px] bg-[#260514] relative overflow-hidden'>
        {isLoading ?
          <Loader desc='Tunggu Sebentar..' />
          :
          renderLandingPage()
        }
      </main>
    </AnimatePresence>
  );
};

export default LandingPage; 