import React, { useState, useEffect } from 'react';
import { TypeInvitation } from '../../contexts/types';
import { CalendarIcon } from '../../assets/images/svg/icon';
import { m } from 'framer-motion';
import { useInvitationContext } from '../../contexts/InvitationContext';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';

type PropsButtonAddToCalendar = {
  data: TypeInvitation
};

const formatDate = (dateStr: string, timeStr: string) => {
  if (!dateStr || !timeStr) return ''; // Return empty string if input is invalid

  const [day, month, year] = dateStr.split('-').map(Number);
  const [hours, minutes] = timeStr.split(':').map(Number);

  return `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}${minutes.toString().padStart(2, '0')}00`;
};

const renderDate = (date: number | Date | null) => {
  if (date) {
    return `${format(date, 'dd', { locale: id })} ${format(date, 'MMMM', { locale: id })} ${format(date, 'yyyy', { locale: id })}`;
  }

  return '';
};


const ButtonAddToCalendar = ({data}: PropsButtonAddToCalendar) => {
  const [deviceType, setDeviceType] = useState('');
  const {marriageDate} = useInvitationContext();
  const date = marriageDate ? new Date(marriageDate) : null; // Konversi marriageDate menjadi Date

  const event = {
    title: `Wedding ${data.nameCandidate} & ${data.namePartner}`,
    description: `Kami dengan bahagia mengundang Bapak/Ibu/Saudara/i ${data.nameGuest} untuk merayakan hari istimewa kami pada tanggal ${renderDate(date)}. Bergabunglah dengan kami dalam merayakan pernikahan kami, momen penuh cinta dan kebahagiaan.\n
    Kehadiran Anda adalah hadiah berharga bagi kami, dan kami tidak sabar untuk berbagi kebahagiaan ini bersama Anda.\n
    Dengan cinta, ${data.nameCandidate} & ${data.namePartner}`,
    location: `${data.marriageCeremonyBuilding}, ${data.marriageCeremonyLocation}`,
    startDate: formatDate(data.marriageCeremonyDate, data.marriageStartTime),
    endDate: formatDate(data.marriageCeremonyDate, data.weddingEndTime),
    timeZone: 'Asia/Jakarta',
  };

  // Google Calendar URL
  const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.title)}&dates=${event.startDate}/${event.endDate}&details=${encodeURIComponent(event.description)}&location=${encodeURIComponent(event.location)}`;

  // Function to create .ics file for Apple Calendar
  const handleICalDownload = () => {
    const icsData = `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Your Organization//NONSGML v1.0//EN
BEGIN:VEVENT
UID:${new Date().toISOString()}
DTSTAMP:${new Date().toISOString().replace(/[-:]/g, '').split('.')[0]}
DTSTART;TZID=${event.timeZone}:${event.startDate}
DTEND;TZID=${event.timeZone}:${event.endDate}
SUMMARY:${event.title}
DESCRIPTION:${event.description}
LOCATION:${event.location}
END:VEVENT
END:VCALENDAR`;

    const blob = new Blob([icsData], { type: 'text/calendar' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'event.ics';
    link.click();
  };

  // Detect device type
  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      setDeviceType('Android');
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setDeviceType('iOS');
    } else {
      setDeviceType('Other');
    }
  }, []);

  const handleGoogleCalendar = () => {
    window.location.href = googleCalendarUrl;
  };

  return (
    <>
      {deviceType === 'Android' && (
        <m.button
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 1, duration: 0.6, ease: [0.45, 0, 0.55, 1] }}
          viewport={{ once: true }}
          onClick={handleGoogleCalendar}
          className="w-full z-40 mt-5 flex flex-row justify-center items-center space-x-2 py-2 px-4 rounded-lg self-center shadow-xl hover:border-[#954d3c] hover:shadow-md border border-[#e8b855] text-[#e8b855]"
        >
          <CalendarIcon /> <span>Save to Google Calendar</span>
        </m.button>
      )}
      {deviceType === 'iOS' && (
        <m.button
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 1, duration: 0.6, ease: [0.45, 0, 0.55, 1] }}
          viewport={{ once: true }}
          onClick={handleICalDownload}
          className="w-full z-40 mt-5 flex flex-row justify-center items-center space-x-2 py-2 px-4 rounded-lg self-center shadow-xl hover:border-[#954d3c] hover:shadow-md border border-[#e8b855] text-[#e8b855]"
        >
          <CalendarIcon /> <span>Save to Apple Calendar</span>
        </m.button>
      )}
      {deviceType === 'Others' && (
        <>
          <m.button
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.6, ease: [0.45, 0, 0.55, 1] }}
            viewport={{ once: true }}
            onClick={handleGoogleCalendar}
            className="w-full z-40 mt-5 flex flex-row justify-center items-center space-x-2 py-2 px-4 rounded-lg self-center shadow-xl hover:border-[#954d3c] hover:shadow-md border border-[#e8b855] text-[#e8b855]"
          >
            <CalendarIcon /> <span>Save to Google Calendar</span>
          </m.button>
          <m.button
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 1.5, duration: 0.6, ease: [0.45, 0, 0.55, 1] }}
            viewport={{ once: true }}
            onClick={handleICalDownload}
            className="w-full z-40 mt-5 flex flex-row justify-center items-center space-x-2 py-2 px-4 rounded-lg self-center shadow-xl hover:border-[#954d3c] hover:shadow-md border border-[#e8b855] text-[#e8b855]"
          >
            <CalendarIcon /> <span>Save to Apple Calendar</span>
          </m.button>
        </>
      )}
    </>
  );
};

export default ButtonAddToCalendar;
