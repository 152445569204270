import React, { RefObject } from 'react';
import { PictureIcon } from '../../../assets/images/svg/icon';
import Caraousel from '../../../components/Carousel';
import { DeviderSolidImg } from '../../../assets/images/svg/image';
import { domAnimation, LazyMotion, m, useInView } from 'framer-motion';

import FlowerSide from '../../../assets/images/webp/flower-side.webp';
import FlowerSideLeaf1 from '../../../assets/images/webp/flower-leaf-1.webp';
import FlowerSideLeaf2 from '../../../assets/images/webp/flower-leaf-2.webp';
import FlowerSideLeaf3 from '../../../assets/images/webp/flower-leaf-3.webp';
import FlowerSideLeaf4 from '../../../assets/images/webp/flower-leaf-4.webp';
import { Leaf } from '../../../components/Leaf';
import ImageWithPreview from '../../../components/ImageWithPreview';
import RunningTextOpacityEffect from '../../../components/RunningTextOpacityEffect';

import Prewed1Img from '../../../assets/images/jpg/prewed_1.jpg';
import Prewed2Img from '../../../assets/images/jpg/prewed_2.jpg';
import Prewed3Img from '../../../assets/images/jpg/prewed_3.jpg';
import Prewed4Img from '../../../assets/images/jpg/prewed_4.jpg';
import Prewed5Img from '../../../assets/images/jpg/prewed_5.jpg';
import Prewed6Img from '../../../assets/images/jpg/prewed_6.jpg';
import PrewedThumb1Img from '../../../assets/images/jpg/prewed_thumb_1.jpg';
import PrewedThumb2Img from '../../../assets/images/jpg/prewed_thumb_2.jpg';
import PrewedThumb3Img from '../../../assets/images/jpg/prewed_thumb_3.jpg';
import PrewedThumb4Img from '../../../assets/images/jpg/prewed_thumb_4.jpg';
import PrewedThumb5Img from '../../../assets/images/jpg/prewed_thumb_5.jpg';
import PrewedThumb6Img from '../../../assets/images/jpg/prewed_thumb_6.jpg';
import PrewedThumb7Img from '../../../assets/images/jpg/prewed_thumb_7.jpg';
import PrewedThumb8Img from '../../../assets/images/jpg/prewed_thumb_8.jpg';


const image = [
  {
    urls: Prewed1Img,
    alt_description: 'prewedding_photos'
  },
  {
    urls: Prewed2Img,
    alt_description: 'prewedding_photos1'
  },
  {
    urls: Prewed3Img,
    alt_description: 'prewedding_photos2'
  },
  {
    urls: Prewed4Img,
    alt_description: 'prewedding_photos3'
  },
  {
    urls: Prewed5Img,
    alt_description: 'prewedding_photos4'
  },
  {
    urls: Prewed6Img,
    alt_description: 'prewedding_photos5'
  },
];

const GallerySection = ({reference} : {reference: RefObject<HTMLDivElement>}) => {
  const isInView = useInView(reference);
  return (
    <>
      <section id='gallerySection' ref={reference} className='flex flex-col relative bg-[#F1EFEA] pt-32 pb-44 space-y-9 text-[#594545] z-30'>
        <LazyMotion features={domAnimation}>
          <div className='w-full absolute -top-[7px] left-[0.5px] -scale-y-100 z-40'>
            <DeviderSolidImg />
          </div>
          <div className='absolute -top-[10px] w-full z-40'>
            <div className='w-[200px] absolute -top-[80px] -left-[30px] -scale-y-100 -scale-x-100'>
              <m.img
                src={FlowerSideLeaf1}
                initial={{ rotate: -8 }}
                animate={isInView && { rotate: [-8, -4, -8, -4, -8] }}
                transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
                alt="side_flower_1"
                width={95}
                loading="lazy"
                className="absolute bottom-9 right-0 z-0"
              />
              <m.img
                src={FlowerSideLeaf2}
                initial={{ rotate: 8 }}
                animate={isInView && { rotate: [8, 4, 8, 4, 8] }}
                transition={{ duration: 4, repeat: Infinity, ease: 'easeInOut' }}
                alt="side_flower_2"
                width={205}
                loading="lazy"
                className="absolute bottom-7 right-7 z-0"
              />
              <m.img
                src={FlowerSideLeaf3}
                initial={{ rotate: 5 }}
                animate={isInView && { rotate: [5, 0, 5, 0, 5] }}
                transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
                alt="side_flower_3"
                width={80}
                loading="lazy"
                className="absolute bottom-12 right-12 z-0"
              />
              <m.img
                src={FlowerSideLeaf4}
                initial={{ rotate: 5 }}
                animate={isInView && { rotate: [5, 0, 5, 0, 5] }}
                transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
                alt="side_flower_3"
                width={95}
                loading="lazy"
                className="absolute bottom-20 right-20 z-0"
              />
              <img
                src={FlowerSide}
                alt="side_flower"
                width={145}
                loading="lazy"
                className="absolute bottom-0 right-0 z-10"
              />
            </div>
            <div className='w-[200px] absolute -top-[80px] -right-[30px] -scale-y-100'>
              <m.img
                src={FlowerSideLeaf1}
                initial={{ rotate: -8 }}
                animate={isInView && { rotate: [-8, -4, -8, -4, -8] }}
                transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
                alt="side_flower_1"
                width={95}
                loading="lazy"
                className="absolute bottom-9 right-0 z-0"
              />
              <m.img
                src={FlowerSideLeaf2}
                initial={{ rotate: 8 }}
                animate={isInView && { rotate: [8, 4, 8, 4, 8] }}
                transition={{ duration: 4, repeat: Infinity, ease: 'easeInOut' }}
                alt="side_flower_2"
                width={205}
                loading="lazy"
                className="absolute bottom-7 right-7 z-0"
              />
              <m.img
                src={FlowerSideLeaf3}
                initial={{ rotate: 5 }}
                animate={isInView && { rotate: [5, 0, 5, 0, 5] }}
                transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
                alt="side_flower_3"
                width={80}
                loading="lazy"
                className="absolute bottom-12 right-12 z-0"
              />
              <m.img
                src={FlowerSideLeaf4}
                initial={{ rotate: 5 }}
                animate={isInView && { rotate: [5, 0, 5, 0, 5] }}
                transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
                alt="side_flower_3"
                width={95}
                loading="lazy"
                className="absolute bottom-20 right-20 z-0"
              />
              <img
                src={FlowerSide}
                alt="side_flower"
                width={145}
                loading="lazy"
                className="absolute bottom-0 right-0 z-10"
              />
            </div>
          </div>
          <div className='self-center text-center px-5 space-y-1 z-30'>
            <div className='w-[40px] h-[40px] bg-[#781444] rounded-full mx-auto flex items-center justify-center text-[#F1EFEA]'>
              <PictureIcon />
            </div>
            <h3 className='font-playFair pt-2 text-3xl'>Galeri</h3>
            <RunningTextOpacityEffect text={'Melalui foto-foto ini, kami ingin berbagi sedikit cerita cinta kami yang telah membawa kami ke hari yang istimewa ini.'} textAlign='center' />
          </div>
          <div className='absolute w-full top-11'>
            <Leaf />
          </div>
          <div className='z-30'>
            <Caraousel images={image} />
            <div className="columns-2 space-y-4 mx-5 mt-0">
              <m.div
                viewport={{ once: true }}
                initial={{ opacity: 0, scale: 0.8, }}
                whileInView={{ opacity: 1, scale: 1, }}
                transition={{ duration: 0.5, ease: [0.45, 0, 0.55, 1] }}
                className='break-inside-avoid'
              >
                  <ImageWithPreview src={PrewedThumb1Img} alt="img_prewed_1" />
              </m.div>
              <m.div
                viewport={{ once: true }}
                initial={{ opacity: 0, scale: 0.8, }}
                whileInView={{ opacity: 1, scale: 1, }}
                transition={{ duration: 0.5, ease: [0.45, 0, 0.55, 1] }}
                className='break-inside-avoid'
              >
                  <ImageWithPreview src={PrewedThumb2Img} alt="img_prewed_2" />
              </m.div>
              <m.div
                viewport={{ once: true }}
                initial={{ opacity: 0, scale: 0.8, }}
                whileInView={{ opacity: 1, scale: 1, }}
                transition={{ duration: 0.5, ease: [0.45, 0, 0.55, 1] }}
                className='break-inside-avoid'
              >
                  <ImageWithPreview src={PrewedThumb3Img} alt="img_prewed_3" />
              </m.div>
              <m.div
                viewport={{ once: true }}
                initial={{ opacity: 0, scale: 0.8, }}
                whileInView={{ opacity: 1, scale: 1, }}
                transition={{ delay: 0.2, duration: 0.5, ease: [0.45, 0, 0.55, 1] }}
                className='break-inside-avoid'
              >
                  <ImageWithPreview src={PrewedThumb4Img} alt="img_prewed_4" />
              </m.div>
              <m.div
                viewport={{ once: true }}
                initial={{ opacity: 0, scale: 0.8, }}
                whileInView={{ opacity: 1, scale: 1, }}
                transition={{ delay: 0.2, duration: 0.5, ease: [0.45, 0, 0.55, 1] }}
                className='break-inside-avoid'
              >
                  <ImageWithPreview src={PrewedThumb5Img} alt="img_prewed_5" />
              </m.div>
              <m.div
                viewport={{ once: true }}
                initial={{ opacity: 0, scale: 0.8, }}
                whileInView={{ opacity: 1, scale: 1, }}
                transition={{ delay: 0.2, duration: 0.5, ease: [0.45, 0, 0.55, 1] }}
                className='break-inside-avoid'
              >
                  <ImageWithPreview src={PrewedThumb6Img} alt="img_prewed_6" />
              </m.div>
              <m.div
                viewport={{ once: true }}
                initial={{ opacity: 0, scale: 0.8, }}
                whileInView={{ opacity: 1, scale: 1, }}
                transition={{ delay: 0.2, duration: 0.5, ease: [0.45, 0, 0.55, 1] }}
                className='break-inside-avoid'
              >
                  <ImageWithPreview src={PrewedThumb7Img} alt="img_prewed_5" />
              </m.div>
              <m.div
                viewport={{ once: true }}
                initial={{ opacity: 0, scale: 0.8, }}
                whileInView={{ opacity: 1, scale: 1, }}
                transition={{ delay: 0.2, duration: 0.5, ease: [0.45, 0, 0.55, 1] }}
                className='break-inside-avoid'
              >
                  <ImageWithPreview src={PrewedThumb8Img} alt="img_prewed_6" />
              </m.div>
            </div>
          </div>
          <div className='w-full absolute left-0 -bottom-1'>
            <DeviderSolidImg />
          </div>
        </LazyMotion>
      </section>
    </>
  );
};

export default React.memo(GallerySection);