import React, { useRef } from 'react';
import LogoBCA from '../../assets/images/webp/bca.webp';
import ChipImg from '../../assets/images/webp/chip.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import useCopyPaste from '../../utils/hooks/useCopyPaste';
import { motion, useScroll, useTransform } from 'framer-motion';

type Props = {
  name: string;
  noAccount: string;
  motion?: {
    scale: number
  };
};

const DebitCard = ({name, noAccount}: Props) => {
  const target = useRef(null);
  const {scrollYProgress} = useScroll({
    target: target,
    offset: ['end 0.90', 'end 0.35'],
    layoutEffect: false, 
  });

  const scale = useTransform(scrollYProgress, [0.2, 0], [1, 0.95]);
  const opacity = useTransform(scrollYProgress, [0, 0.2], [0, 1]);

  const {handleCopy} = useCopyPaste(noAccount);
  return (
    <motion.div ref={target} viewport={{ once: true }} style={{scale, opacity}} className='bg-gradient-to-br from-[#A47E3B] to-[#87652a36] backdrop-blur-sm p-4 rounded-md relative overflow-hidden border border-[#6e501c]'>
      <div className='z-0 w-[200px] h-[200px] bg-gradient-to-bl from-[#553d13] rounded-lg absolute opacity-10 left-[-40px] top-[-40px] rotate-45' />
      <div className='z-0 w-[100px] h-[100px] bg-gradient-to-bl from-[#553d13] rounded-md absolute opacity-10 left-36 top-28 rotate-45' />
      <div className='relative z-10'>
        <div className='flex justify-end'>
          <img
            src={LogoBCA}
            alt='logo_bca'
            loading='lazy'
            width={80}
            className=''
          />
        </div>
        <div className='mt-4 mb-6'>
          <img
            src={ChipImg}
            alt='logo_bca'
            loading='lazy'
            width={40}
            className='mb-2'
          />
          <h4 className='text-xl'>a.n {name}</h4>
          <span>{noAccount}</span>
        </div>
        <button
          className='w-full bg-gradient-to-r from-[#e8b855] to-[#954d3c] text-[#260514] rounded-md p-2 flex space-x-2 justify-center items-center'
          onClick={handleCopy}
        >
          <FontAwesomeIcon icon={faClipboard} /> <span>Salin Rekening</span>
        </button>
      </div>
    </motion.div>
);
};

export default DebitCard;
