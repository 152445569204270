import { apiUrl } from '../utils/GLOBAL_VARIABLE';


export const getInvitationData = async (idInvitation: string | null) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/invitation/details/${idInvitation}`);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const { data, status } = await response.json();


    return {
      status,
      result: data
    };
  } catch (error) {
    console.error(error);
  }
};

export const getBankAccountData = async (idInvitation: string | null) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/event/bank-account/${idInvitation}`);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const { data, status } = await response.json();


    return {
      status,
      result: data
    };
  } catch (error) {
    console.error(error);
  }
};