import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { m } from 'framer-motion';
import 'swiper/css';
import 'swiper/css/pagination';
import './Carousel.css';
import ImageWithPreview from '../ImageWithPreview';

type Props = {
  images: {
    urls: string,
    alt_description: string
  }[],
}

const Caraousel = ({ images }: Props) => {
  return (
    <m.div
      viewport={{ once: true }}
      initial={{ opacity: 0, scale: 0.8, }}
      whileInView={{ opacity: 1, scale: 1, }}
      transition={{ duration: 0.5, ease: [0.45, 0, 0.55, 1] }}
     className='mx-5 mb-5 rounded-md overflow-hidden shadow-md'
    >
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        speed={800}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination, Autoplay]}
        autoplay={{delay: 2500, disableOnInteraction: false}}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper: unknown) => console.log(swiper)}
      >
        {images.map((res, index) => (<SwiperSlide key={index}>
          <ImageWithPreview src={res.urls} alt={res.alt_description || 'photo'} />
        </SwiperSlide>))
        }
      </Swiper >
    </m.div>
  );
};

export default Caraousel;