import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CloseButton, Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import React, { useState } from 'react';

type Props = {
  src: string;
  alt: string
};

const ImageWithPreview = ({src, alt}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  function previewImg() {    
    setIsOpen(true);
  }

  function closePreviewImg() {
    setIsOpen(false);
  }

  const renderButton = () => {
    return (
      <Button
        className={'absolute right-0 rounded-full bg-white/30 backdrop-blur-2xl text-black w-12 h-12 mx-3 my-3 text-lg'}
        onClick={closePreviewImg}
      >
        <FontAwesomeIcon icon={faXmark} />
      </Button>
    );
  };

  return (
    <>
      <img
        className="h-auto max-w-full rounded-md cursor-pointer shadow-md"
        src={src}
        alt={alt}
        loading='lazy'
        onClick={previewImg}
      />
      
      <Dialog open={isOpen} onClose={closePreviewImg} className="relative z-50">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 backdrop-blur-sm bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <CloseButton as={renderButton}>Cancel</CloseButton>

              <img
                className="object-fill"
                src={src}
                alt={alt}
                loading='lazy'
              />
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ImageWithPreview;
