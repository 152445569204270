/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { DoubleHeartIcon, GiftIcon, HomeIcon, MessageIcon, PauseIcon, PictureIcon, PinLocationIcon, PlayIcon } from '../../assets/images/svg/icon';
import { Link } from 'react-router-dom';
import useMusic from './hooks/useMusic';
import { domAnimation, LazyMotion, m } from 'framer-motion';
// import useQuery from '../../hooks/useQuery';
// import { motion } from 'framer-motion';

const menu = [
  { icon: <HomeIcon />, label: 'Home', directTo: 'homeSection' },
  { icon: <DoubleHeartIcon />, label: 'Pengantin', directTo: 'brideSection' },
  { icon: <MessageIcon />, label: 'Cerita', directTo: 'loveStorySection' },
  { icon: <PinLocationIcon />, label: 'Lokasi', directTo: 'locationSection' },
  { icon: <GiftIcon />, label: 'Hadiah', directTo: 'loveGiftSection' },
  { icon: <PictureIcon />, label: 'Galeri', directTo: 'gallerySection' },
];

const Navigation = ({activeSection}: any) => {
  const { audioRef, isPlaying, setIsPlaying } = useMusic();

  const handleButtonClick = () => {
    setIsPlaying(!isPlaying);
  };

  const handleScrollToSection = (e: React.MouseEvent<HTMLAnchorElement>, sectionId: string) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

   // Event listener to restart the music when it ends
   const handleMusicEnded = () => {
    if (audioRef.current) {
      audioRef.current.play(); // Replay the music
    }
  };

  // activeNow.current = location.hash.slice(1); // safe hash for further use after navigation]
  return (
    <LazyMotion features={domAnimation}>
    <div className='m-5 fixed z-50 bottom-0 w-[calc(100%-40px)] md:w-[calc(450px-40px)] space-y-3'>
      <nav className='rounded-lg shadow-md bg-[#1f020fcf] backdrop-blur-sm md:w-[calc(450px-40px)]'>
        <div className='flex py-3 flex-row gap-3'>
          {menu.map(({ icon, label, directTo }, index) => {
            return (
              <Link
                // to={`/BayuDanRizkia/details?invitation=${invitationId}#${directTo}`}
                className={`flex flex-col flex-1 items-center text-[#efbe67] space-y-1 ${directTo !== activeSection && 'opacity-40'}`}
                to="/" // Remain on the same page
                onClick={(e) => handleScrollToSection(e, directTo)} 
                key={index}
              >
                <m.span
                  className={`text-2xl icon baseline ${directTo !== activeSection ? 'h-full self-center items-center text-3xl' : ''}`}
                  initial={{ scale: 1 }}
                  animate={{ 
                    scale: directTo === activeSection ? [1, 1.2, 1] : 1,
                  }}
                  transition={{ 
                    duration: 0.6, 
                    ease: 'easeInOut',
                    times: [0, 0.5, 1]
                  }}
                >{icon}</m.span>
                <m.label 
                  className={`text-sm ${directTo !== activeSection ? 'hidden' : ''}`}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: directTo !== activeSection ? 0 : 1, y: directTo !== activeSection ? 10 : 0 }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                >{label}</m.label>
              </Link>
            );
          })}
        </div>
      </nav>
      <div>
        <audio ref={audioRef} autoPlay={isPlaying} src="/music/Sezairi - It's You.mp3" onEnded={handleMusicEnded} />
        <button
          onClick={handleButtonClick}
          className='w-full flex flex-row justify-center items-center space-x-2 bg-[#1f020fcf] backdrop-blur-sm border text-[#efbe67] border-[#efbe67] py-2 px-4 rounded-lg self-center shadow-xl md:w-[calc(450px-40px)]'>
          {
            isPlaying ? (<><PauseIcon /> <span>Pause Music</span></>) : (<><PlayIcon /> <span>Putar Music</span></>)
          }
        </button>
      </div>
    </div>
    </LazyMotion>
  );
};

export default Navigation;
