import React, { RefObject, useCallback } from 'react';
import { domAnimation, LazyMotion, m } from 'framer-motion';
// import { FlowerVerticalLeafL1, FlowerVerticalLeafL2, FlowerVerticalLeafR1, , FlowerVerticalSingle } from '../../../assets/images/svg/image';
import FlowerVerticalSingle from '../../../assets/images/svg/flowerVertical_single.svg';
import FlowerVerticalLeafL1 from '../../../assets/images/svg/flowerVertical_leaf_L1_single.svg';
import FlowerVerticalLeafL2 from '../../../assets/images/svg/flowerVertical_leaf_L2_single.svg';
import FlowerVerticalLeafR1 from '../../../assets/images/svg/flowerVertical_leaf_R1_single.svg';
import FlowerVerticalLeafR2 from '../../../assets/images/svg/flowerVertical_leaf_R2_single.svg';

import { CalendarIcon, ClockIcon, MapIcon, PinLocationIcon } from '../../../assets/images/svg/icon';
import { Link } from 'react-router-dom';
import { useInvitationContext } from '../../../contexts/InvitationContext';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { parseDate } from '../../../utils/hooks/useGetInvitationData';
import useScrollToSection from '../hooks/useScrollToSection';
import WaterColor4 from '../../../assets/images/webp/watercolor_4.webp';
import ButtonAddToCalendar from '../../../components/ButtonAddCalendar';

const now = new Date();

const LocationSection = ({reference} : {reference: RefObject<HTMLDivElement>}) => {

  const {ref} = useScrollToSection();

  const {data: {invitation}} = useInvitationContext();
  

  const ceremonyStartDate = parseDate(invitation?.marriageCeremonyDate, invitation?.marriageStartTime);
  const ceremonyEndDate = parseDate(invitation?.marriageCeremonyDate, invitation?.marriageEndTime);
  const receptionStartDate = parseDate(invitation?.weddingReceptionDate, invitation?.weddingStartTime);
  const receptionEndDate = parseDate(invitation?.weddingReceptionDate, invitation?.weddingEndTime);
  
  const dateActive = useCallback((start: Date | null, end: Date | null, type: 'ceremony' | 'reception') => {
    
    if (!start && !end) {
      return;
    }

    if (type === 'ceremony' && start && now.getTime() < start?.getTime()) {
      return 'card-active card-comming-soon';
    }

    if (start && end && (now.getTime() >= start?.getTime() && now.getTime() <= end?.getTime())) {
      return 'card-active card-on-going';
    }

    if (end && now.getTime() > end?.getTime()) {
      return 'opacity-50';
    }

    return;
  }, []);
  

  return (
    <section
      id='locationSection'
      ref={reference}
      className='flex flex-col relative text-[#F1EFEA] overflow-hidden z-30 bg-[#260514]'
    >
      <div className='absolute -right-52 bottom-0 z-0'>
        <img
          src={WaterColor4}
          alt='img_watercolor_3'
          loading='lazy'
          className='object-cover'
        />
      </div>
      <LazyMotion features={domAnimation}>
        <div className='pt-28 pb-36 px-5 flex flex-col space-y-9 z-10'>
          <div className='self-center space-y-1' ref={ref}>
            <div className='w-[40px] h-[40px] bg-[#781444] rounded-full mx-auto flex items-center justify-center text-[#F1EFEA]'>
              <PinLocationIcon />
            </div>
            <h3 className='font-playFair pt-2 text-3xl'>Waktu & Lokasi</h3>
          </div>
          <div className='space-y-5'>
            <div className='font-libreBaskerville'>
              <m.div
                initial={{ marginTop: '10%', scale: 0.85 ,opacity: 0}}
                whileInView={{ marginTop: '0%', scale: 1 ,opacity: ceremonyEndDate && now.getTime() > ceremonyEndDate?.getTime() ? 0.5 : 1 }}
                transition={{ delay: 0.3, duration: 1, ease: [0.45, 0, 0.55, 1] }}
                viewport={{ once: true }}
                className={`px-[16px] py-[30px] rounded-lg w-full border-2 border-[#594545] border-opacity-10 mx-auto text-center space-y-1 shadow-sm relative backdrop-blur-md bg-[#8b56a02b] ${dateActive(ceremonyStartDate, ceremonyEndDate, 'ceremony')}`}>
                <h4 className='font-playFair text-2xl'>Akad</h4>
                <div className='flex flex-col items-center opacity-60 text-md'>
                  <div className='flex items-center space-x-2'>
                    <CalendarIcon />
                    <label>
                      {ceremonyStartDate && format(ceremonyStartDate, 'EEEE, dd MMMM yyyy', {locale: id})}
                    </label>
                  </div>
                  <div className='flex items-center space-x-2'>
                    <ClockIcon />
                    <label>
                      {invitation?.marriageStartTime} - {invitation?.marriageEndTime}
                    </label>
                  </div>
                </div>
              </m.div>
              <m.div
                initial={{ marginTop: '10%', scale: 0.85 ,opacity: 0 }}
                whileInView={{ marginTop: '3%', scale: 1 ,opacity: receptionEndDate && now.getTime() > receptionEndDate?.getTime() ? 0.5 : 1  }}
                transition={{ delay: 0.7, duration: 1, ease: [0.45, 0, 0.55, 1] }}
                viewport={{ once: true }}
                className={`px-[16px] py-[30px] rounded-lg w-full border-2 border-[#594545] border-opacity-10 mx-auto text-center space-y-1 shadow-sm relative backdrop-blur-md bg-[#8b56a02b] ${dateActive(receptionStartDate, receptionEndDate, 'reception')}`}>
                <h4 className='font-playFair text-2xl'>Resepsi</h4>
                <div className='flex flex-col items-center opacity-60 text-md'>
                  <div className='flex items-center space-x-2'>
                    <CalendarIcon />
                    <label>
                    {receptionStartDate && format(receptionStartDate, 'EEEE, dd MMMM yyyy', {locale: id})}
                    </label>
                  </div>
                  <div className='flex items-center space-x-2'>
                    <ClockIcon />
                    <label>
                      {invitation?.weddingStartTime} - {invitation?.weddingEndTime}
                    </label>
                  </div>
                </div>
              </m.div>
            </div>
            <div className='relative pb-[10px]'>
              <m.hr
                initial={{width: '0%'}}
                whileInView={{width: '100%'}}
                transition={{ delay: 0.7, duration: 1, ease: [0.45, 0, 0.55, 1] }}
                viewport={{ once: true }}
                className='absolute top-0 bottom-0 my-auto z-0 left-1/2 -translate-x-1/2 border-[#A47E3B] border-[0.5px]'
              />
              <m.div
                initial={{ scale: 0.85 , opacity: 0 }}
                whileInView={{ scale: 1 , opacity: 1  }}
                transition={{ delay: 0.8, duration: 1, ease: [0.45, 0, 0.55, 1] }}
                viewport={{ once: true }}
                className='mx-auto relative z-10 w-[160px] h-auto'
              >
                <m.div
                  initial={{ rotate: 0 }}
                  animate={{ rotate: [3, 0, 3, 0, 3] }}
                  transition={{ duration: 3, repeat: Infinity, ease: 'easeInOut' }}
                  
                >
                  <img
                    src={FlowerVerticalLeafL2}
                    alt='flower_gallery_left_leaf1'
                    width={160}
                    loading='lazy'
                    className='absolute top-1'
                  />
                </m.div>
                <m.div
                  initial={{ rotate: 0 }}
                  animate={{ rotate: [3, 0, 3, 0, 3] }}
                  transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
                  
                >
                  <img
                    src={FlowerVerticalLeafL1}
                    alt='flower_gallery_left_leaf1'
                    width={160}
                    loading='lazy'
                    className='absolute top-0'
                  />
                </m.div>
                <m.div
                  initial={{ rotate: 0 }}
                  animate={{ rotate: [0, 3, 0, 3, 0] }}
                  transition={{ duration: 3, repeat: Infinity, ease: 'easeInOut' }}
                  
                >
                  <img
                    src={FlowerVerticalLeafR2}
                    alt='flower_gallery_left_leaf1'
                    width={160}
                    loading='lazy'
                    className='absolute top-1'
                  />
                </m.div>
                <m.div
                  initial={{ rotate: 0 }}
                  animate={{ rotate: [0, 3, 0, 3, 0] }}
                  transition={{ duration: 5, repeat: Infinity, ease: 'easeInOut' }}
                  
                >
                  <img
                    src={FlowerVerticalLeafR1}
                    alt='flower_gallery_left_leaf1'
                    width={160}
                    loading='lazy'
                    className='absolute top-0'
                  />
                </m.div>
              </m.div>
              <img
                src={FlowerVerticalSingle}
                alt='flower_gallery_left_leaf1'
                width={160}
                loading='lazy'
                className='mx-auto relative z-10'
              />
              {/* <FlowerVerticalSingle  /> */}
            </div>
            <m.div
              initial={{ scale: 0.85 , opacity: 0 }}
              whileInView={{ scale: 1 , opacity: 1  }}
              transition={{ delay: 1, duration: 1, ease: [0.45, 0, 0.55, 1] }}
              viewport={{ once: true }}
              // style={{ opacity }}
              className='space-y-4 z-20'>
              <iframe
                src={`https://maps.google.com/maps?q=${invitation?.marriageCeremonyCoordinate}&z=15&output=embed`}
                width="80%"
                height="150px"
                loading="lazy"
                className='shadow-lg rounded-lg mx-auto relative'
                allow="fullscreen"
                allowFullScreen
              />
              <div className='text-center space-y-1'>
                <h2 className='text-2xl font-poppins'>{invitation?.marriageCeremonyBuilding}</h2>
                <p className='opacity-60 text-xs font-libreBaskerville'>{invitation?.marriageCeremonyLocation}</p>
              </div>
            </m.div>
            <Link to={`https://www.google.com/maps/place/${invitation?.marriageCeremonyCoordinate}`} target="_blank" rel="noopener noreferrer">
              <m.button
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 1, duration: 0.6, ease: [0.45, 0, 0.55, 1] }}
                viewport={{ once: true }}
                className='w-full z-40 mt-8 flex flex-row justify-center items-center space-x-2 bg-gradient-to-r from-[#260514] to-[#b33e76] text-[#F1EFEA] py-2 px-4 rounded-lg self-center shadow-xl hover:bg-[#781444] hover:shadow-md'>
                <MapIcon /> <span>Lihat Peta</span>
              </m.button>
            </Link>
            <ButtonAddToCalendar data={invitation} />
          </div>
        </div>
      </LazyMotion>
    </section >
  );
};

export default React.memo(LocationSection);