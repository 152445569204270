import React, { useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import HomeSection from './HomeSection/HomeSection';
import BrideSection from './BrideSection/BrideSection';
import LocationSection from './LocationSection/LocationSection';
import GallerySection from './GallerySection/GallerySection';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';

import useScrollToSection from './hooks/useScrollToSection';
import useGetInvitationData from '../../utils/hooks/useGetInvitationData';
import LoveGiftSection from './LoveGiftSection/LoveGiftSection';
import useActiveSection from '../../hooks/useActiveSection';
import NotFoundPage from '../ErrorPage/NotFoundPage';
import LoveStorySection from './LoveStorySection';

const InvitationPage = () => {
  const sectionRefs = {
    home: useRef<HTMLDivElement>(null),
    bride: useRef<HTMLDivElement>(null),
    loveStory: useRef<HTMLDivElement>(null),
    location: useRef<HTMLDivElement>(null),
    loveGift: useRef<HTMLDivElement>(null),
    gallery: useRef<HTMLDivElement>(null),
  };
  const activeSection = useActiveSection(Object.values(sectionRefs));
  const {scrollY} = useScrollToSection();
  const {data: {invitation}} = useGetInvitationData();

  if (!invitation) {
    return <NotFoundPage />; 
  };

  return (
    <>
      <Navigation activeSection={activeSection} />

      <AnimatePresence mode='sync'>
        <HomeSection key='home-section' reference={sectionRefs.home} scroll={{ scrollY: scrollY }} />
        <BrideSection key='bride-section' reference={sectionRefs.bride} scroll={{ scrollY: scrollY }} />
        <LoveStorySection key='love-story-section' reference={sectionRefs.loveStory} />
        <LocationSection key='location-section' reference={sectionRefs.location} />
        <LoveGiftSection key='love-gift-section' reference={sectionRefs.loveGift} />
        <GallerySection key='gallery-section' reference={sectionRefs.gallery} />
      </AnimatePresence>
      <Footer />
    </>
  );
};

export default React.memo(InvitationPage);