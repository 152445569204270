import React from 'react';
import { useCountdownTimer } from './hooks/useCountdown';
import { mapLabel } from '../../utils/GLOBAL_VARIABLE';

type Props = {
  date: Date | null;
}

type LoadCoundownProps = {value: {item: {key: string, label: string}, date: Date | null}}

const LoadCountdown = ({value}: LoadCoundownProps) => {  
  const timeRemaining = useCountdownTimer(value.date);  

  if (!value.date) {
    return (
      <div className='flex flex-col items-center gap-2'>
        <div className="h-3.5 animate-pulse bg-gray-400 rounded-full w-[35px]" />
        <div className="h-2.5 animate-pulse bg-gray-400 rounded-full w-[20px]" />
      </div>
    );
  }

  return <>
      <label className='text-xl'>{timeRemaining[value.item.key]}</label>
      <label className='text-xs'>{value.item.label}</label>
    </>;
};

const CountdownTimer = ({ date }: Props) => {

  return <div className='flex flex-col space-y-5 relative'>
    <label className=' text-[#260514] text-xs tracking-[4px] bg-[#D09EA9] w-full py-1'>
      Menuju Hari Pernikahan
    </label>
    <div className='grid grid-cols-4 gap-5 mx-5 z-20'>
      {mapLabel.map((item) => (
        <div className='flex flex-col py-1 bg-[#35061e] rounded-2xl shadow-lg shadow-[#260514]' key={item.key}>
          <LoadCountdown value={{item, date}} />
        </div>
      ))}
    </div>
  </div>;
};

export default CountdownTimer;