import React, { useRef } from 'react';
import { useEffect } from 'react';
import styles from './Leaf.module.css';

const leafImages = [
  require('../../assets/images/webp/leaf1.webp'),
  require('../../assets/images/webp/leaf2.webp'),
  require('../../assets/images/webp/leaf3.webp'),
];


export const Leaf = () => {
  const leavesRef = useRef<HTMLDivElement>(null);
  const count = 1;

  useEffect(() => {
    function createLeaf() {
      const randomLeafImage = leafImages[Math.floor(Math.random() * leafImages.length)];

      const leaf = document.createElement('img');
      leaf.src = randomLeafImage;
      leaf.className = styles.leaf;
      leaf.style.left = `${Math.random() * 100}vw`;
      leaf.style.animationDuration = `${Math.random() * 8 + 5}s`; // Random duration between 1s and 3s
      leaf.style.zIndex = Math.floor(Math.random() * 40).toString();
      // document.querySelector(`.${styles.leaves}`)?.appendChild(leaf);
      leavesRef.current?.appendChild(leaf);
      setTimeout(() => {
        leaf.remove();
      }, parseFloat(leaf.style.animationDuration) * 1000);
    }

    const leafInterval = setInterval(() => {
      for (let i = 0; i < count; i++) {
        createLeaf();
      }
    }, 900);

    return () => {
      clearInterval(leafInterval); // Cleanup interval on component unmount
    };
  }, [count]);

  return <div ref={leavesRef} className={styles.leaves} aria-hidden="true" />;
};