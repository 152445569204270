import React, { useRef } from 'react';
import { motion, MotionValue, useScroll, useTransform } from 'framer-motion';

type RunningTextOpacityEffectType = {
  text: string;
  textAlign?: 'left' | 'center' | 'right' | undefined;
  className?: string;
}

const defineTextAlign = (textAlign: string | undefined) => {
  if (textAlign === 'center') {
    return 'justify-center';
  };

  if (textAlign === 'right') {
    return 'justify-end';
  }

  return 'justify-start';
};

const RunningTextOpacityEffect = ({text, textAlign, className}: RunningTextOpacityEffectType) => {
  const desc = useRef(null);
  const {scrollYProgress} = useScroll({
    target: desc,
    offset: ['end 0.9', 'start 0.25'],
    layoutEffect: false, 
  });

  const arrayWords = text.split(' ');

  return <p ref={desc} className={`opacity-60 flex flex-wrap gap-x-1 ${defineTextAlign(textAlign)} ${className}`}>
  {arrayWords.map((value, index) => {
    const start = index / arrayWords.length;
    const end = start + (1/arrayWords.length);
    return (
      <Word key={index} range={[start, end]} progress={scrollYProgress}>{value}</Word>
    );
  })}
</p>;
};

const Word = ({children, range, progress}: {children: React.ReactNode, range: number[], progress: MotionValue<number> }) => {
  const opacity  = useTransform(progress, range, [0,1]);
  return(
    <motion.span style={{opacity}}>{children}</motion.span>
  );
};

export default RunningTextOpacityEffect;
