import React, { RefObject, useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { Devider1Img, TornPaperImg } from '../../../assets/images/svg/image';
import { MessageIcon } from '../../../assets/images/svg/icon';


const events = [
  {
    key: 1,
    title: 'Bagaimana Semuanya Dimulai',
    description: 'Kisah kami dimulai dari pertemuan yang tak direncanakan pada masa kuliah. Kami tidak pernah menyangka bahwa hari itu akan menjadi awal dari perjalanan panjang bersama. Dari obrolan santai hingga momen-momen sederhana, kami saling menemukan kenyamanan satu sama lain.',
    date: new Date('2016-10-01'),
    imageUrl: '/images/berita1.jpg' // Ganti dengan path gambar yang sesuai
  },
  {
    key: 2,
    title: 'Menjadi Lebih Dekat',
    description: 'Seiring berjalannya waktu, perasaan kami tumbuh semakin kuat. Kami belajar memahami satu sama lain, saling mendukung, dan berbagi kebahagiaan dalam setiap langkah kecil. Apa yang awalnya hanya kebetulan, berubah menjadi sesuatu yang lebih dalam dan bermakna.',
    date: new Date('2016-11-16'),
    imageUrl: '/images/berita2.jpg' // Ganti dengan path gambar yang sesuai
  },
  {
    key: 3,
    title: 'Menghadapi Cobaan',
    description: 'Ada masa ketika kami terlalu sibuk dengan mimpi dan masalah masing-masing. Hubungan kami diuji, dan kami sempat berjarak satu sama lain. Namun, cinta sejati selalu menemukan jalannya. Setelah melewati masa-masa sulit, kami dipertemukan kembali dengan hati yang lebih kuat dan keyakinan untuk melangkah bersama.',
    date: new Date('2021-06-15'),
    imageUrl: '/images/berita4.jpg' // Ganti dengan path gambar yang sesuai
  },
  {
    key: 4,
    title: 'Sebuah Bab Baru',
    description: 'Sekarang, kami siap memulai babak baru dalam hidup kami. Dengan keyakinan dan cinta, kami memutuskan untuk menjalani hidup bersama, menghadapi masa depan sebagai pasangan. Kami mengundang kalian untuk menjadi bagian dari hari bahagia ini, saat kami mengikat janji untuk selamanya.',
    date: new Date('2024-10-05'),
    imageUrl: '/images/berita3.jpg' // Ganti dengan path gambar yang sesuai
  }
];

const CardStory = ({event, key}: {event: {key: number; date: Date; title: string; description: string}; key: number}) => {
  const targetStory = useRef(null);

  const {scrollYProgress} = useScroll({
    target: targetStory,
    offset: ['end 0.90', 'end 0.45'],
    layoutEffect: false, 
  });

  const scale = useTransform(scrollYProgress, [0.2, 0], [1, 0.95]);
  const opacity = useTransform(scrollYProgress, [0, 0.2], [0, 1]);

  const scaleDate = useTransform(scrollYProgress, [0.5, 0.3, 0], [1, 1.2, 1]);
  const height = useTransform(scrollYProgress, [0, 1], ['0%', '100%']);  

  return (
    <div className="flex flex-row space-x-5" key={key}>
      <div className='flex flex-[0.1] flex-col'>
        <motion.div style={{scale: scaleDate}} viewport={{ once: true }} className='flex flex-col bg-[#781444] justify-center text-white text-center rounded-md w-[50px] h-[70px]'>
          <label className='text-sm'>{format(event.date, 'yyyy', { locale: id })}</label>
          <label className='text-xl'>{format(event.date, 'MMM', { locale: id })}</label>
        </motion.div>
        <motion.div style={event.key !== events.length ? {height}: {}} className={'w-1 self-center bg-[#781444a1]'} />
      </div>
      <motion.div
        ref={targetStory}
        style={{scale, opacity}}
        className="p-5 mb-10 w-full flex-1 bg-[#260514] rounded-lg text-[#F1EFEA]"
        viewport={{ once: true }}
      >
        <h3 className='text-lg font-bold'>{event.title}</h3>
        <p className='opacity-50 mt-2'>{event.description}</p>
      </motion.div>
    </div>
  );
};

const LoveStorySection = ({reference} : {reference: RefObject<HTMLDivElement>}) => {
  return (
    <section id='loveStorySection' ref={reference} className='flex flex-col relative bg-[#F1EFEA] text-[#260514] overflow-hidden py-40 z-30'>
      <div className='absolute w-full top-0'>
        <Devider1Img />
      </div>
      <div className='self-center text-center space-y-1'>
        <div className='w-[40px] h-[40px] bg-[#781444] rounded-full mx-auto flex items-center justify-center text-white'>
          <MessageIcon />
        </div>
        <h3 className='font-playFair text-3xl'>Cerita Cinta</h3>
      </div>
      <div className="pt-10 px-5">
        {events.map((event, index) => (
          <CardStory key={index} event={event} />
        ))}
      </div>
      <div className='text-[#260514] absolute left-0 bottom-0 w-full'>
        <TornPaperImg />
      </div>
    </section>
  );
};

export default React.memo(LoveStorySection);
